

export const directionsData = [
    {
        title: 'ВЕБ 3.0',
        text : ['Розробка та підтримка смарт-контрактів.', 'Взаємодія зі смарт-контрактами через front-end <b>та back-end</b>.', 'Розробка сервісів на базі блокчейну.', 'Створення NFT колекцій.', 'Тестування та пошук вразливостей у смарт-контрактаx.']
    },
    {
        title: 'ФІНТЕХ',
        text: ['Розробка бізнес додатків в яких використовується функціонал криптовалют.', 'Розробка сервісів для обробки fiat операцій.']
    },
    {
        title: 'МАРКЕТИНГ',
        text: [ 'Community менеджмент.', 'Копірайтинг.', 'UI/UX.', 'Графічний дизайн.', 'Motion дизайн.'],
        textMobile: [ 'Community менеджмент.', 'Копірайтинг.', 'UI/UX.', 'Графічний дизайн.', 'Motion дизайн.'],
        className: ['smm', 'design', 'copy', 'community', 'analitic']
    },
    {
        title: 'IT АУТСОРС',
        text: ['Створення сайтів.', 'Аналіз поточних вихідних кодів.', 'Аналіз хмарної інфраструктури.', 'Доопрацювання проектів.']
    }
]

export const routePathes = ['/', '/about', /*'/cases',*/ '/directions', '/contacts']


// export const caseImg = ['/assets/img/case-img.jpg']

export const cases = [
  {
    num: '1',
    href: '/cases/crypto-processing',
    title: 'Crypto Processing',
    link:  {
      full: 'coming soon',
      short : 'coming soon'
    },
    category : 'фінтех',
    year: 2022,
    description: 'Crypto Processing - комплексне фінансове рішення, що поєднує в собі технології крипто процесингу, інструменти бухгалтерського обліку та систему управління ролями користувачів.'
  },
  {
    num: '2',
    href: '/cases/ezra',
    title: 'Ezra',
    link:  {
      full: 'closed beta',
      short : 'closed beta'
    },
    category : 'it аутсорс',
    year: 2021,
    description: 'SaaS-платформа для створення та управління партнерською мережею. EZRA надає можливість компаніям і продавцям шукати та залучати партнерів, які просуватимуть їхні товари та послуги, заробляючи на цьому комісійні.'
  },
];

export const rihonImages = [
  {
    img: {
      jpg: require('../assets/img/rihon-images/1.png'),
      webp: require('../assets/img/rihon-images/1.webp')
    }
  },
  {
    img: {
      jpg: require('../assets/img/rihon-images/2.png'),
      webp: require('../assets/img/rihon-images/2.webp')
    }
  },
  {
    img: {
      jpg: require('../assets/img/rihon-images/3.png'),
      webp: require('../assets/img/rihon-images/3.webp')
    }
  },
  {
    img: {
      jpg: require('../assets/img/rihon-images/4.png'),
      webp: require('../assets/img/rihon-images/4.webp')
    }
  },
  {
    img: {
      jpg: require('../assets/img/rihon-images/5.png'),
      webp: require('../assets/img/rihon-images/5.webp')
    }
  },
  {
    img: {
      jpg: require('../assets/img/rihon-images/6.png'),
      webp: require('../assets/img/rihon-images/6.webp')
    }
  },
  {
    img: {
      jpg: require('../assets/img/rihon-images/7.png'),
      webp: require('../assets/img/rihon-images/7.webp')
    }
  },
]

export const aboutSlidesText = [
  'Передали <br/> тепловізори', 
  'Передали автомобіль <br/> Mercedes Vito', 
  'Передали автомобіль <br/> Mitsubishi Pajero Sport' , 
  'Передали автомобіль <br/> Mercedes Vito',
  'Передали <br/> бронежилети та підсумки',
  'Передали <br/> тепловізори та коліматорну оптику',
  'Передали <br/> Android планшет Oukitel RT1',
  'Передали багатоканальний блок <br/> швидкого заряджання',
  'Отримали <br/> прапори від військових',
  'Передали <br/> тактичні окуляри',
  'Передали <br/> 70 тактичних дощовиків',
  'Передали додаткові <br/> акумулятори для DJI Mavic 3',
  'Передали дрон DJI Mavic 3, <br/> бронежилети та тактичні сумки',
  'Передали <br/> глушник та тактичний бронешолом',
  'Передали <br/> тепловізор',
  'Передали рюкзаки, глушник <br/> та тактичний бронешолом',
  'Передали <br/> тактичні бронешоломи',
  'Передали <br/> кровоспинні турнікети',
  'Передали дрони DJI Mavic 3 <br/> та коліматорну оптику'
]

export const cryptoAccountingContent = {
  'list_1' : [
    'Забезпечити централізоване керування всіма фінансовими операціями компанії, включаючи фіатні та криптовалютні баланси, транзакції, звичайні та масові виплати, заробітну плату та обмін валют, у єдиному інтегрованому середовищі.',
    'Автоматизувати внутрішні процеси з метою підвищення ефективності та зниження обсягу ручної роботи.',
    'Забезпечити повноцінний бухгалтерський облік криптовалют з урахуванням цифрових активів.',
    'Здійснювати централізоване відстеження ключових фінансових метрик з метою забезпечення належного контролю та моніторингу.'
  ],
  'list_2' : [
    'Встановлення персонального облікового запису: Забезпечується можливість створення і управління власним корпоративним рахунком, що дозволяє керувати фінансовими активами та здійснювати фінансові операції.',
    'Безпека фінансових активів: Гарантується захист власних коштів, що дозволяє знизити ризик втрати чи несанкціонованого доступу до них.',
    'Можливість виконання валютного обміну: Надається можливість швидкого обміну фінансових активів на різні валютні одиниці відповідно до поточного курсу.',
    'Зручність поповнення та виведення коштів: Забезпечується зручність та ефективність процесу поповнення рахунку та виведення коштів з урахуванням встановлених процедур та політик компанії.'
  ],
  'list_3' : [
    'Привабити бізнес-структури для використання платформи.',
    'Створити систему управління бізнесом, яка відповідала б бізнесу.',
    'Мотивувати індивідуальних користувачів до активного використання платформи.',
    'Розробити зручну систему управління особистими фінансами для користувачів.',
    'Генерувати прибуток шляхом стягнення комісійних платежів, впровадження нового функціоналу, а також підписки на продукт.',
    'Сформувати позитивний імідж бренду, втілити важливу роль в галузі та постійно збільшувати ринкову присутність платформи.'
  ],
  'list_4' : [
    'Можливість самостійного розгортання (Self-hosted).',
    'Забезпечення захисту фінансів.',
    'Система налаштувань та конфігуратор бізнес-правил щодо безпеки, фінансових операцій та доступу.',
    'Інтегрований модуль для обміну валют.',
    'Створення криптовалютних гаманців та фіатних рахунків.',
    'Випуск віртуальних платіжних карток.',
    'Внутрішні, зовнішні та массові виплати.',
    'Здійснення централізованого бухгалтерського обліку.',
    'Фінансова інформаційний панель (dashboard) для власників бізнесу та керівників підрозділів із можливістю порівняння метрик.',
    'Експорт фінансових даних.',
    'Платформа як сервіс / Програмне забезпечення як сервіс (PaaS / SaaS).',
    'Багатоплатформність.',
    'Можливість інтеграції через API.',
    'Виконання процедур KYC & AML.'
  ]
}

export const ezraContent = {
  "list_1" : [
    'Генерувати трафік на веб-сайти або цільові сторінки рекламодавців для залучення потенційних клієнтів.',
    'Збільшити конверсію за рахунок ефективних рекламних зусиль.',
    'Максимізувати прибуток шляхом оптимізації рекламних стратегій.'
  ],
  "list_2" : [
    'Розширити охоплення ринку, використовуючи аудиторію та мережі вебмайстрів.',
    'Отримувати потенційних клієнтів та збільшувати конверсію за допомогою цільових маркетингових кампаній вебмайстрів.',
    'Підвищити впізнаваність, популярність та репутацію бренду завдяки позитивним рекомендаціям та відгукам вебмайстрів.',
    'Оптимізувати рентабельність інвестицій (ROI) шляхом ефективного управління партнерськими відносинами, моніторингу ефективності та оптимізації коефіцієнтів конверсії.'
  ],
  "list_3" : [
    'Залучити до мережі вебмайстрів та рекламодавців.',
    'Забезпечити зручну для користувача платформу з системами відстеження, звітності та платежів.',
    'Отримувати дохід, стягуючи збори або комісії.',
    'Побудувати міцну репутацію бренду, встановити вплив у галузі та розширити ринкову частку платформи.'
  ],
  "list_4" : [
    'Дозволяє створювати партнерські мережі по вертикалях, які вас цікавлять.',
    'Інтеграція із зовнішніми системами за допомогою загальноприйнятих методів: API, Redirect / Direct, Postback / API синхронізація.',
    'Платформа може бути встановлена локально для повної ізоляції даних клієнтів (self-hosted).',
    'Простота в налаштуванні та управлінні.',
    'Готові до використання інтеграції з основними системами на ринку.',
    'Попередньо визначені шаблони для швидкого налаштування.',
    'Гнучка система ролей користувачів.',
    'Кабінети та дашборди для рекламодавців, вебмайстрів і менеджерів.',
    'Вибір інструментів для аналізу та оптимізації кампаній.',
    'Персоналізовані кабінети, метрики та звіти.',
    'Конфігурація та правила платежів.',
    'Автоматизовані фінансові звіти.',
    'Наявність модуля криптообробки.',
    'Управління кампаніями.',
    'Підтримка міграції.'
  ]
}

export const policyLists = {
  'list_1' : [
    "Обліковий запис означає унікальний обліковий запис, створений для Вас для доступу до нашого Сервісу або його частин.",
    "Афілійована особа означає організацію, яка контролює, контролюється або перебуває під спільним контролем зі стороною, де \"контроль\" означає володіння 50% або більше акцій, паїв або інших цінних паперів, що дають право голосу при обранні директорів або інших керівних органів.",
    "Компанія (у цій Угоді - \"Компанія\", \"Ми\", \"Нас\" або \"Наш\") означає ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"ЮНІКІТ ГРУП\" (ТОВ \"ЮНІКІТ ГРУП\"), Україна, м. Київ, вул. Паньківська, буд. 14.",
    "Файли cookie - це невеликі файли, які розміщуються на Вашому комп'ютері, мобільному пристрої або будь-якому іншому пристрої веб-сайтом і містять інформацію про історію Вашого відвідування цього веб-сайту, серед іншого.",
    "Країна відноситься до: Україна",
    "Пристрій - будь-який пристрій, який може отримати доступ до Сервісу, наприклад, комп'ютер, мобільний телефон або цифровий планшет.",
    "Персональні дані - це будь-яка інформація, що стосується ідентифікованої особи або особи, яку можна ідентифікувати.",
    "Сервіс - Веб-сайт.",
    "Постачальник послуг - будь-яка фізична або юридична особа, яка обробляє дані від імені Компанії. Це стосується сторонніх компаній або фізичних осіб, найнятих Компанією для сприяння Сервісу, надання Сервісу від імені Компанії, виконання послуг, пов'язаних з Сервісом, або надання допомоги Компанії в аналізі того, як використовується Сервіс.",
    "Дані про використання - це дані, зібрані автоматично, або згенеровані в результаті використання Сервісу, або з самої інфраструктури Сервісу (наприклад, тривалість відвідування сторінки).",
    "Веб-сайт - веб-сайт Uniqit, доступний за адресою https://uniqitgroup.com/.",
    "Ви - фізична особа, яка отримує доступ до Сервісу або використовує його, або компанія чи інша юридична особа, від імені якої така особа отримує доступ до Сервісу або використовує його, залежно від обставин."
  ],
  'list_2' : [
    "Адреса електронної пошти",
    "Ім'я та прізвище",
    "Номер телефону",
    "Дані про використання"
  ],
  'list_3' : [
    "Файли cookie або браузерні файли cookie. Файл cookie - це невеликий файл, який зберігається на Вашому пристрої. Ви можете налаштувати свій браузер так, щоб він відмовлявся від усіх файлів cookie або вказував, коли надсилається файл cookie. Однак, якщо Ви не приймаєте файли cookie, Ви не зможете користуватися деякими частинами нашого Сервісу. Якщо Ви не налаштували Ваш браузер таким чином, щоб він відмовлявся від використання файлів cookie, наш Сервіс може використовувати файли cookie.",
    "Веб-маяки. Деякі розділи нашого Сервісу та наші електронні листи можуть містити невеликі електронні файли, відомі як веб-маяки (також відомі як прозорі gif-файли, піксельні теги та однопіксельні gif-файли), які дозволяють Компанії, наприклад, підраховувати користувачів, які відвідали ці сторінки або відкрили електронний лист, а також збирати іншу пов'язану з цим статистику веб-сайту (наприклад, реєструвати популярність певного розділу та перевіряти цілісність системи та сервера)."
  ],
  'list_4' : [
    "Тип: Сесійні файли cookie",
    "Адмініструється: Нами",
    "Призначення: Ці файли cookie необхідні для надання Вам послуг, доступних на Веб-сайті, і для того, щоб Ви могли використовувати деякі його функції. Вони допомагають аутентифікувати користувачів і запобігти шахрайському використанню облікових записів користувачів. Без цих файлів cookie послуги, які Ви запитували, не можуть бути надані, і Ми використовуємо ці файли cookie лише для надання Вам цих послуг."
  ],
  'list_5' : [
    "Тип: Постійні файли cookie",
    "Адмініструється: Нами",
    "Призначення: Ці файли cookie визначають, чи погодилися користувачі на використання файлів cookie на Веб-сайті."
  ],
  'list_6' : [
    "Тип: Постійні файли cookie",
    "Адмініструється: Нами",
    "Призначення: Ці файли cookie дозволяють нам запам'ятовувати вибір, який Ви робите під час користування Веб-сайтом, наприклад, запам'ятовувати Ваші дані для входу або мовні налаштування. Мета цих файлів cookie полягає в тому, щоб надати Вам більш персоналізований досвід і уникнути необхідності повторного введення Ваших налаштувань кожного разу, коли Ви користуєтеся Веб-сайтом."
  ],
  'list_7' : [
    "Надавати та підтримувати наш Сервіс, включаючи моніторинг використання нашого Сервісу.",
    "Для управління Вашим обліковим записом: для управління Вашою реєстрацією як користувача Сервісу. Надані Вами Персональні дані можуть надати Вам доступ до різних функціональних можливостей Сервісу, які доступні Вам як зареєстрованому користувачеві.",
    "Для виконання договору: розробка, дотримання та виконання договору купівлі-продажу продуктів, товарів або послуг, які Ви придбали, або будь-якого іншого договору з Нами за допомогою Сервісу.",
    "Для зв'язку з Вами: Зв'язуватися з Вами електронною поштою, телефонними дзвінками, SMS або іншими еквівалентними формами електронного зв'язку, такими як push-повідомлення мобільного додатку про оновлення або інформаційні повідомлення, пов'язані з функціональними можливостями, продуктами або договірними послугами, включаючи оновлення системи безпеки, коли це необхідно або доцільно для їх реалізації.",
    "Надавати Вам новини, спеціальні пропозиції та загальну інформацію про інші товари, послуги та події, які ми пропонуємо, подібні до тих, які Ви вже придбали або про які Ви запитували, якщо Ви не вирішили не отримувати таку інформацію.",
    "Для управління Вашими запитами: Щоб відвідувати та керувати Вашими запитами до нас.",
    "Для ділових переказів: Ми можемо використовувати Вашу інформацію для оцінки або проведення злиття, відчуження, реструктуризації, реорганізації, ліквідації або іншого продажу або передачі деяких або всіх наших активів, чи то як безперервного підприємства, чи як частини банкрутства, ліквідації або подібної процедури, в якій Персональні дані, що зберігаються у нас про користувачів наших Сервісів, є одними з активів, що передаються.",
    "Для інших цілей: Ми можемо використовувати Вашу інформацію для інших цілей, таких як аналіз даних, виявлення тенденцій використання, визначення ефективності наших рекламних кампаній, а також для оцінки та вдосконалення нашого Сервісу, продуктів, послуг, маркетингу та вашого досвіду."
  ],
  'list_8' : [
    "Постачальникам послуг: Ми можемо передавати Вашу особисту інформацію постачальникам послуг для моніторингу та аналізу використання нашого Сервісу, а також для зв'язку з Вами.",
    "Для ділових переказів: Ми можемо ділитися або передавати Вашу особисту інформацію у зв'язку або під час переговорів про будь-яке злиття, продаж активів Компанії, фінансування або придбання всього або частини нашого бізнесу іншою компанією.",
    "З афілійованими особами: Ми можемо ділитися вашою інформацією з нашими афілійованими особами, і в цьому випадку ми вимагатимемо від них дотримання цієї Політики конфіденційності. До афілійованих осіб належать наша материнська компанія та будь-які інші дочірні компанії, партнери по спільних підприємствах або інші компанії, які ми контролюємо або які перебувають під спільним з нами контролем.",
    "З діловими партнерами: Ми можемо ділитися Вашою інформацією з нашими діловими партнерами, щоб запропонувати Вам певні продукти, послуги або акції.",
    "З іншими користувачами: коли Ви ділитеся особистою інформацією або іншим чином взаємодієте в публічних зонах з іншими користувачами, така інформація може бути переглянута всіма користувачами і може бути публічно поширена зовні.",
    "З Вашої згоди: Ми можемо розкривати Вашу особисту інформацію для будь-яких інших цілей з Вашої згоди."
  ],
  'list_9' : [
    "Дотримуватися юридичних зобов'язань",
    "Захищати права та власність Компанії",
    "Запобігати або розслідувати можливі правопорушення у зв'язку з Сервісом",
    "Захищати особисту безпеку Користувачів Сервісу або громадськості",
    "Захищати від юридичної відповідальності"
  ]

}

export const formDataModel = {
  name: '',
  email: '',
  phone: '',
  company: '',
  stack: ["ВЕБ 3.0"],
  task: ''
}