import React , {useEffect, useState , useRef} from 'react'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {motion} from 'framer-motion'


import { cases } from '../../utils/content';
import PageLine from '../PageLine'
import CasesSlide from '../CasesSlide';

import {ReactComponent as ArrowLeft} from '../../assets/img/icons/slider-arrow-left.svg'
import {ReactComponent as ArrowRight} from '../../assets/img/icons/slider-arrow-right.svg'

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Keyboard, Mousewheel } from "swiper/core";


// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";	
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Navigation } from "swiper";


SwiperCore.use([Mousewheel]);

const Cases = ({animate = true , exitDisabled = false, disableCaseAnimation = true,videoIsLoop , currentSlide, setCurrentSlide, routePush, disableLink}) => {
	const [scrollDirection, setScrollDirection] = useState('up');
	const [canScroll, setCanScroll] = useState(true);
	const [disableScroll, setDisableScroll] = useState(true)
	const [swiperInstance, setSwiperInstance] = useState(null);
	const swiperRef = useRef(null);

	const {t} = useTranslation();

	const [isAnimatingExit, setIsAnimatingExit] = useState(false);

	useEffect(() => {
		if (exitDisabled) {
			setIsAnimatingExit(true);
		}
	}, [])

	const handleButtonClick = (route) => {
	  setIsAnimatingExit(true);
	  routePush(null, true, route)
	};

	const handleScroll = (e) => {
		e.stopPropagation()
		const delta = e.deltaY;

		if (disableScroll) {
			if (canScroll && scrollDirection === 'up' && delta < 0) {
				// console.log('scroll up')
				routePush(null, false, null, -10)
				setDisableScroll();
				setCanScroll(false)
			}
			if (canScroll && scrollDirection === 'down' && delta > 0) {
				// console.log('scroll down')
				routePush(null, false, null, 10)
				setDisableScroll();
				setCanScroll(false)
			}
		}
	}

	useEffect(() => {
		if (swiperInstance && currentSlide !== swiperInstance.activeIndex) {
		  swiperInstance.slideTo(currentSlide, 0);
		}
	  }, [currentSlide, swiperInstance]);
	
	return (
		<motion.section className="cases"
			initial={animate && disableCaseAnimation ? {  opacity: 0 } : {}}
			animate={animate && disableCaseAnimation && videoIsLoop ? {  opacity: 1, transition: 1 } : {}}
			exit={isAnimatingExit ? {} : { opacity: 0, transition: {duration: 1, delay: 1}}}

		>
			<motion.div className="cases__wrapper"
				initial={animate && disableCaseAnimation ? { scale: 0.4, transformOrigin: 'top center'} : {}}
				animate={animate && disableCaseAnimation && videoIsLoop ? {  scale: 1,transition: {duration: 0.5, delay: 0.1}} : {}}
				exit={isAnimatingExit ? {} : { scale: 0.7, transition: {duration: 0.7, delay:1}}}
			>
				<div className="cases__line">
					<PageLine name={t('cases.line-title')}/>
				</div>
				<div className="cases__slides" onWheel={handleScroll}>
					<Swiper
						ref={swiperRef}
						// mousewheel={true}
						mousewheel={{
							eventsTarget: '.cases__slides'
						}}
						touchEventsTarget='container'
						effect={"coverflow"}
						slidesPerView={1}
						initialSlide={currentSlide}
						draggable
						speed={1000}
						passiveListeners={false}
						onSwiper={(swiper) => setSwiperInstance(swiper)}
						onSlideChange={(swiper) => {
							setCanScroll(false)
							if (swiper.activeIndex === 0) {
								setCanScroll(false)
								setTimeout(() => {
									setCanScroll(true)
									setScrollDirection('up')
								}, 1200)

							} else if (swiper.activeIndex === swiper.slides.length - 1) {
								setCanScroll(false)
								setTimeout(() => {
									setCanScroll(true)
									setScrollDirection('down')
									// console.log('User can scroll down');
								}, 1200)
							} else {
								setCanScroll(false)
							}

						}}
						coverflowEffect={{
							rotate: 0,
							stretch: 0,
							depth: 0,
							modifier: 1,
							scale: 0.8,
							slideShadows: false,
						}}
						breakpoints={{
							991 : {
								// spaceBetween : 150
							},
							320 : {
								// spaceBetween: 80
							}
						}}
						modules={[EffectCoverflow, Navigation, Mousewheel]}
						navigation={{
                            nextEl: ".cases__slides-arrows-right",
                            prevEl: ".cases__slides-arrows-left",
                        }}
					>
						{
							cases.map((item) => (
								<SwiperSlide key={item.description}>
									<CasesSlide handleButtonClick={handleButtonClick} data={item} routePush={routePush} setCurrentSlide={setCurrentSlide} disableLink={disableLink}/>
								</SwiperSlide>
							))
						}
					</Swiper>

					<div className="cases__slides-arrows">
						<div style={{opacity: swiperInstance?.activeIndex === 0 && 0.5}} className="cases__slides-arrows-left">
							<ArrowLeft />
						</div>
						<div style={{opacity: swiperInstance?.activeIndex === swiperInstance?.slides.length - 1 && 0.5}} className="cases__slides-arrows-right">
							<ArrowRight />
						</div>
					</div>
				</div>
			</motion.div>
		</motion.section>
	)
}

export default Cases