import React , {useState} from 'react'
import { Trans } from 'react-i18next';

import { Link } from 'react-router-dom'
import parse from 'html-react-parser';


import {ReactComponent as ArrowIcon} from '../assets/img/icons/arrow-button.svg'
import { useTranslation } from 'react-i18next';


function Accordion({text, title, textMobile, handleTabMobile, handleContactMobile}) {
    const [isActive, setIsActive] = useState(false);

    const {t} = useTranslation();

    const handleToggle = (e, title) => {
        let contentEl = e.currentTarget.parentElement.querySelector('.directions__block-content');
        if (isActive) {
            contentEl.style.maxHeight = '0px';
        } else {
            contentEl.style.maxHeight = contentEl.scrollHeight + 80 + 'px';
        }
        setIsActive(!isActive)
        if (handleTabMobile) {
            handleTabMobile(title);
        }
    }
    // console.log(title)
    return (
        <div className={isActive ? 'directions__block-item directions__block-item-active' : 'directions__block-item'}>
            <div className="directions__block-top" 
                onClick={e => handleToggle(e, title)}
            >
                <div className="directions__block-top-title">{t(`directions.tabs.${title}.title`)}</div>
                <Link to='/contacts' onClick={e => handleContactMobile(e, title)}>
                    <ArrowIcon />
                </Link>
            </div>
            <div className="directions__block-content">
                <ul className='directions__block-list'>
                    {
                        textMobile ? 
                        textMobile.map((item, i) => (
                            <li key={i}>
                                <Trans i18nKey={`directions.tabs.${title}.text.${i}`} components={{ 1: <b/> }} />
                            </li>
                        )) : 
                        text.map((item, i) => (
                            <li key={i}>
                                <Trans i18nKey={`directions.tabs.${title}.text.${i}`} components={{ 1: <b/> }} />
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Accordion