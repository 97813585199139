import { useEffect, useState, useRef, useContext } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { AnimatePresence , motion } from "framer-motion";
import TagManager from "react-gtm-module";
import { formDataModel, routePathes } from "./utils/content";
import { LocationContext } from "./components/LocationContext";
import { videoData } from "./components/VideoDownloadControl";
import { cases } from "./utils/content";
import Cookies from "js-cookie";


import Header from "./components/Header";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Cases from "./components/pages/Cases";
import Directions from "./components/pages/Directions";
import Contacts from "./components/pages/Contacts";
import RihonCase from "./components/pages/RihonCase";
import CryptoAccounting from './components/pages/CryptoAccounting';
import EzraCase from './components/pages/EzraCase'
import BrokerNearCase from './components/pages/BrokerNearCase'
import Preloader from "./components/Preloader";
import AboutUs from "./components/pages/AboutUs";
import MobileVhSet from "./components/MobileVhSet";
import SwipeHandler from "./components/SwipeHandler";
import VideoDownloadControl from "./components/VideoDownloadControl";
import CookiesCheck from './components/Cookies'
import Terms from "./components/pages/Terms";
import Policy from "./components/pages/Policy";

function App() {

  const location = useLocation();
  const navigate = useNavigate();
  const pathLength = location.pathname.split('/').length;

  const delayUpdateRef = useRef(null);
  const disableCaseAnimationRef = useRef(true);
  const delayOverlayRef = useRef(0)


  const [headerActive, setHeaderActive] = useState(false);
  const [isCookiesAccept, setIsCookiesAccept] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [currentTab, setCurrentTab] = useState({direction : "ВЕБ 3.0", choosed: false});
  const [currentSlide, setCurrentSlide] = useState(0);
  const [lastCallTime, setLastCallTime] = useState(0);
  const [cookieHeigth , setCookieHeight] = useState(0);
  const [isCookiesVisible, setCookiesVisible] = useState(false);
  const [cookieError, setCookieError] = useState(false);
  const [formData, setFormData] = useState(formDataModel)
  const [disableLink, setDisableLink] = useState(false);
  
  const [currentVideo, setCurrentVideo] = useState({
    loopedVideo : videoData[location.pathname]?.loopedVideo,
    transitionVideo: videoData[location.pathname]?.transitionVideo
  });

  // video states
  const [curVideoIdx, setCurVideoIdx] = useState(1);
  const [videoIsLoop, setVideoIsLoop] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [previousPath, setPreviousPath] = useState(location.pathname);

  useEffect(() => {
    const handlePopState = () => {
      const pathname = window.location.pathname;
      handleVideoChange(false, pathname, previousPath);
      setPreviousPath(pathname);
    };
  
    window.addEventListener('popstate', handlePopState);
  
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [previousPath]);
  
  

  useEffect(() => {

    handleMobile();
    window.addEventListener('resize' , handleMobile);

    delayUpdateRef.current = videoData[location.pathname]?.duration || 2500;

    const idx = cases.findIndex(item => item.href === location.pathname);

    if (idx >= 0) {
      setCurrentSlide(idx);
    }
    
    const isCookies = Cookies.get('is_cookies_accept');
    if (!isCookies) {
      setIsCookiesAccept(true)
    }
    
    return () => {
      window.removeEventListener('resize', handleMobile);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      disableCaseAnimationRef.current = true;
    }, 3000);
    return () => clearTimeout(timeout);
  }, [disableCaseAnimationRef.current]);
  
  useEffect(() => {

    setPreviousPath(location.pathname);
    setOverlayDelay();
    TagManager.dataLayer({
      dataLayer: {
        event : 'pageChange', 
        page : location.pathname
      }
    })
  }, [location])

  useEffect(() => {


    window.addEventListener("wheel", routePush);
    return () => {
      window.removeEventListener("wheel", routePush);
    }
  
  }, [location, isMobile , isLoading, isSettingsOpen]);

  // disable single case animation


  const handleAnimationDisable = () => {
    disableCaseAnimationRef.current = false;
  };


  const handleMobile = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }else {
      setIsMobile(false);
    }
  }

  const handleDisableLink = (del) => {
    setDisableLink(true);
    setTimeout(() => {
      setDisableLink(false);
    }, del.current)
  }

  // scroll page push

  const routePush = (e, click, backRoute , deltaMobile) => {
    const now = Date.now();
    const delta = deltaMobile ? deltaMobile : e?.deltaY;
    const path = location.pathname;
    const idx = routePathes.findIndex((elem) => elem === path);
    const docElem = document.documentElement
    const scrollTop = docElem.scrollTop;


    // const scrollBottom = docElem.scrollHeight - docElem.scrollTop === docElem.clientHeight;
    if (isLoading && !isMobile) {
      return
    }

    if (path === backRoute && backRoute) {
      return
    }

    if (now - lastCallTime > delayUpdateRef.current) {
      if (click) {
        setLastCallTime(now);
        handleVideoChange(false, backRoute);
        return;
      }
    }
  
    if (path === '/about-us' || path === '/terms' || path === '/policy' || path.split('/').length === 3 || isSettingsOpen || headerActive) {
      return;
    }
  
    if (now - lastCallTime > delayUpdateRef.current) {
      setLastCallTime(now);
      // console.log(now - lastCallTime, delayUpdateRef.current)
      if (delta < 0 && scrollTop === 0) {

        if (idx > 0) {
          handleVideoChange(false, routePathes[idx - 1]);
          navigate(routePathes[idx - 1]);
        }
      } else if (delta > 0) {

        if (idx < routePathes.length - 1) {
          handleVideoChange(true);
          navigate(routePathes[idx + 1]);
        }
      }
    }
  };
  // fade out animation 

  const fadeOutChange = () => {
    setFadeOut(state => !state);
  }

  const handleVideoChange = (delta , routePath, previousRoutePath) => {
    const path = location.pathname;
    if (delta) {
      if (!isMobile) {
        setVideoIsLoop(false);
      }
      // set Debounce delay
      if (videoData[path].duration && !isMobile) {
        delayUpdateRef.current = videoData[path].duration
      } else {
        delayUpdateRef.current = 2500
      }
      // direction page dont have transition video
      if (path !== '/directions') {

        if  (path === '/cases') {
          setTimeout(() => {
            setCurVideoIdx(() => 2);
          }, 1800)
        } else {
          setCurVideoIdx(() => 2);
        }
      }
      else {
        setTimeout(() => {
          setCurrentVideo((state) => ({
            ...state,
            loopedVideo: videoData['/contacts'].loopedVideo
          }))
        }, 2000)
      }
      // fadeOut on page without transition video
      if (path === '/directions') {
        fadeOutChange();
      }

      handleDisableLink(delayUpdateRef);
      
    }
    if (!delta) {
      delayUpdateRef.current = 2500;

      const pathName = previousRoutePath ? previousRoutePath : path

      // timeout change video
      let timeout = 0

      if ( pathName !== '/') {
        timeout = 2000;
      } else {
        timeout = 1000
      }

      if (pathName === '/about-us' || pathName === '/terms' || pathName === '/policy' || pathName?.includes('cases/')) {
        timeout = 1000
      }
      if (pathName === '/cases' && routePath?.includes('cases/')) {
        timeout = 0
      } else if (pathName?.includes('cases/') && routePath === '/cases') {
        timeout = 2500
      }

      
      if (disableCaseAnimationRef.current && !routePath?.includes('cases/')) {
        fadeOutChange()
      }

      setVideoIsLoop(true);
      setTimeout(() => {
        setCurrentVideo(() => ({
          loopedVideo : videoData[routePath]?.loopedVideo,
          transitionVideo: videoData[routePath]?.transitionVideo
        }))
        setCurVideoIdx(() => 1)
      }, timeout)
      handleDisableLink(delayUpdateRef);

    }
  }

// change transition video

  const transitionVideoSwitch = () => {
    setVideoIsLoop(true);
    setCurrentVideo((state) => ({
      ...state,
      transitionVideo: videoData[location.pathname].transitionVideo
    }))
	  setCurVideoIdx(() => 1);
  };

  // change looped video when transition video playing

  const loopVideoSwitch = () => {
    setCurrentVideo((state) => ({
      ...state,
      loopedVideo: videoData[location.pathname].loopedVideo
    }))
  }

  const setOverlayDelay = () => {
    if ( location.pathname !== '/') {
      delayOverlayRef.current = 1;
    } else {
      delayOverlayRef.current = 0
    }
    
    if (location.pathname === '/about-us' || location.pathname === '/terms' || location.pathname === '/policy' || location.pathname?.includes('cases/')) {
      delayOverlayRef.current = 0
    }
  }

  // overlay fadeOut delay

  return (
    <LocationContext.Provider value={location.pathname}>
      <SwipeHandler routePush={routePush}>
      <motion.div
        className="app"
      >
        <MobileVhSet/>
        {!isMobile && <VideoDownloadControl isMobile={isMobile} setIsLoading={setIsLoading} setCurrentVideo={setCurrentVideo}/>}
        <AnimatePresence>
          {(isLoading && !isMobile) ? 
              <Preloader/> 
            : null
          }
        </AnimatePresence>

        {isCookiesAccept &&
            <CookiesCheck 
              routePush={routePush} 
              handleAnimationDisable={handleAnimationDisable} 
              isSettingsOpen={isSettingsOpen} 
              setSettingsOpen={setSettingsOpen} 
              setCookieHeight={setCookieHeight} 
              disableLink={disableLink}
              setCookiesVisible={setCookiesVisible}
              isCookiesVisible={isCookiesVisible}
            />
        }
        
        <AnimatePresence mode="wait" initial={!location.pathname?.includes('cases/')}>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home transitionVideoLoaded={isLoading} isMobile={isMobile}/>} />
            <Route path="/about" element={<About videoIsLoop={videoIsLoop} routePush={routePush} isMobile={isMobile} disableLink={disableLink}/>} />
            <Route path="/about-us" element={<AboutUs videoIsLoop={videoIsLoop} routePush={routePush} setCurrentSlide={setCurrentSlide} disableLink={disableLink}/>} />
            {/* <Route path="/cases" element={<Cases disableCaseAnimation={disableCaseAnimationRef.current} videoIsLoop={videoIsLoop} animate={true} disableLink={disableLink} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} routePush={routePush}/>} /> */}
            <Route path="/directions" element={<Directions videoIsLoop={videoIsLoop} currentTab={currentTab} setCurrentTab={setCurrentTab} disableLink={disableLink} isMobile={isMobile} routePush={routePush}/>} />
            <Route path="/contacts" element={<Contacts videoIsLoop={videoIsLoop} routePush={routePush} currentTab={currentTab} setCurrentTab={setCurrentTab} setCookiesVisible={setCookiesVisible} formData={formData} setFormData={setFormData} setCookieError={setCookieError} cookieError={cookieError}/>} />
            {/* <Route path="/cases/rihon" element={<RihonCase handleAnimationDisable={handleAnimationDisable} disableLink={disableLink} currentSlide={currentSlide} isMobile={isMobile} routePush={routePush}/>} /> */}
            {/* <Route path="/cases/crypto-processing" element={<CryptoAccounting handleAnimationDisable={handleAnimationDisable} disableLink={disableLink} currentSlide={currentSlide} isMobile={isMobile}  routePush={routePush}/>} /> */}
            {/* <Route path="/cases/ezra" element={<EzraCase handleAnimationDisable={handleAnimationDisable} disableLink={disableLink} currentSlide={currentSlide} isMobile={isMobile}  routePush={routePush}/>} /> */}
            {/* <Route path="/cases/brokernear" element={<BrokerNearCase handleAnimationDisable={handleAnimationDisable} disableLink={disableLink} currentSlide={currentSlide} isMobile={isMobile}  routePush={routePush}/>} /> */}
            <Route path="/terms"  element={<Terms routePush={routePush} cookieHeigth={cookieHeigth} disableLink={disableLink}/>}/>
            <Route path="/policy" element={<Policy routePush={routePush} cookieHeigth={cookieHeigth} disableLink={disableLink}/>}/>
          </Routes>
        </AnimatePresence>
        
        <AnimatePresence mode="wait" initial={false}>
        {pathLength < 3 && <Header disableHeaderAnimation={disableCaseAnimationRef.current} previousPath={previousPath} disableLink={disableLink} routePush={routePush} isActive={headerActive} setIsActive={setHeaderActive}/>}
        </AnimatePresence>

        <AnimatePresence mode="wait" initial={false}>
          <motion.div className="overlay"
            initial={{opacity: 1}}
            animate={{opacity: 0, transition: {duration: 1}}}
            exit={{opacity: 1, transition: {duration: 1, delay: delayOverlayRef.current}}}
            key={fadeOut} 
          ></motion.div>
        </AnimatePresence>
        <AnimatePresence mode="wait" initial={false}>
          {
            !isMobile ? <motion.div className="video">
              <div className="video__overlay"></div>
              <ReactPlayer
                url={currentVideo.loopedVideo}
                style={{ opacity: curVideoIdx === 1 ? 1 : 0 }}
                playing={curVideoIdx === 1}
                muted
                loop={!videoData[location.pathname]?.disableLoop}
                width="100%"
                height="100%"
                position="fixed"
                preload="auto"
                playsInline
              />

              <ReactPlayer
                url={currentVideo.transitionVideo}
                style={{position:'fixed', top: 0, left: 0 , opacity: curVideoIdx === 2 ? 1 : 0 }}
                playing={curVideoIdx === 2}
                muted
                loop={false}
                width="100%"
                height="100%"
                position="fixed"
                preload="auto"
                playsInline
                onStart={loopVideoSwitch}
                onEnded={transitionVideoSwitch}
              />
            </motion.div> : 
            null
          }
        </AnimatePresence>
      </motion.div>
      </SwipeHandler>
    </LocationContext.Provider>
  );
}

export default App;
