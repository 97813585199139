import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import LinksBlock from './LinksBlock'
import {motion} from 'framer-motion'
import { useTranslation } from 'react-i18next'
import HeaderLanguageToggle from './HeaderLanguageToggle'

import {ReactComponent as Logo} from '../assets/img/logo.svg'

const Header = ({disableHeaderAnimation , routePush, isActive, setIsActive, disableLink, previousPath}) => {
    const location = useLocation();
    const {t} = useTranslation()

    const handleToggle = () => {
        if (window.innerWidth < 767) {
            setIsActive(!isActive);
        }
    }
    const handleLogoToggle = () => {
        if (window.innerWidth < 767 && isActive) {
            setIsActive(false);
        }
    }

    const handleWheel = (e) => {
        e.stopPropagation();
    }

    let background = '';

    // if (location.pathname === '/about-us' || location.pathname === '/contacts') {
    //     background = 'header__background-gradient'
    // } else if (location.pathname === '/terms' || location.pathname === '/policy') {
    //     background = 'header__background-black'
    // }

    return (
        <motion.header className={`header ${background}`}
            onWheel={handleWheel}
            initial={{opacity: 0}}
            animate={!disableHeaderAnimation ? {opacity: 1, transition : { delay: 2.6, duration: .5}} : {opacity: 1, transition: {duration: .5, delay : location.pathname === '/contacts' ? 1 : 0}}}
            exit={location.pathname.includes('/cases/') && previousPath === '/contacts' ? { opacity: 0, transition: { duration: 1, delay: 1 } } : {}}
        >
            <div className="header__wrapper">
                <Link to='/' className={`header__logo ${disableLink && 'link-disable'}`}>
                    <Logo onClick={(e) => routePush(e, true, '/')}/>
                </Link>
                <Link to='/' className='header__logo header__logo-mobile'>
                    <Logo onClick={handleLogoToggle}/>
                </Link>
                <div className="header__line">
                    <div className="header__line-block"></div>
                </div>
                <nav className='header__nav'>
                    <ul className="header__nav-links">
                        <li className={`header__nav-link link link--metis ${disableLink && 'link-disable'}`}>
                            <Link to='/about' onClick={(e) => routePush(e, true, '/about')}>
                                <div>{t('header.about')}</div>
                            </Link>
                        </li>
                        {/* <li className={`header__nav-link link link--metis ${disableLink && 'link-disable'}`}>
                            <Link to='/cases' onClick={(e) => routePush(e, true, '/cases')}>
                                <div>{t('header.cases')}</div>
                            </Link>
                        </li> */}
                        <li className={`header__nav-link link link--metis ${disableLink && 'link-disable'}`}>
                            <Link to='/directions' onClick={(e) => routePush(e, true, '/directions')}>
                                <div>{t('header.directions')}</div>
                            </Link>
                        </li>
                        <li className={`header__nav-link ${disableLink && 'link-disable'}`}>
                            <Link to='/contacts' onClick={(e) => routePush(e, true, '/contacts')}>
                                <div className='button button-white button--bestia'>
                                    <span>{t('header.contacts')}</span>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.63673 7.7143L6.63673 6.04788L14.541 6.04788V13.9522H12.8746V8.89281L7.22599 14.5414L6.04747 13.3629L11.6961 7.7143L6.63673 7.7143Z" fill="white"/>
                                    </svg>
                                    <div className="button__bg"></div>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </nav>
                <nav className={isActive ? 'header__nav header__nav-mobile header__nav-mobile-active' : 'header__nav header__nav-mobile'}>
                    <ul className="header__nav-links">
                        <li className='header__nav-link'>
                            <Link onClick={handleToggle} to='/'>{t('header.main')}</Link>
                        </li>
                        <li className='header__nav-link'>
                            <Link onClick={handleToggle} to='about'>{t('header.about')}</Link>
                        </li>
                        {/* <li className='header__nav-link'>
                            <Link onClick={handleToggle} to='cases'>{t('header.cases')}</Link>
                        </li> */}
                        <li className='header__nav-link'>
                            <Link onClick={handleToggle} to='directions'>{t('header.directions')}</Link>
                        </li>
                        <li className='header__nav-link '>
                            <Link onClick={handleToggle} to='contacts'>{t('header.contacts')}</Link>
                        </li>
                    </ul>
                    <div className="header__mobile-bottom">
                        <div className="header__mobile-bottom-links">
                            <LinksBlock/>
                        </div>
                        <HeaderLanguageToggle/>
                    </div>
                </nav>
                <div className="header__line header__line-small">
                    <div className="header__line-block"></div>
                </div>
                <HeaderLanguageToggle/>
                <div className={isActive ? 'header__hamburger header__hamburger_active' : 'header__hamburger'}
                         onClick={handleToggle}>
                        <div className="header__hamburger-line header__hamburger-line_1"></div>
                        <div className="header__hamburger-line header__hamburger-line_2"></div>
                        <div className="header__hamburger-line header__hamburger-line_3"></div>
                </div>
            </div>
        </motion.header>
    )
}

export default Header